import { apiGet, apiPost } from './service'

export async function listBoard(data) {
	return await apiPost("/api/Board/list", data);
}

export async function fullListBoard(data) {
	return await apiPost("/api/Board/fulllist", data);
}

export function getBoard(data) {
	let url = "/api/Board/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postBoard(data) {
	return apiPost("/api/Board/edit", data);
}

export function addBoard(data) {
	return apiPost("/api/Board/add", data);
}

export function deleteBoard(data) {
	return apiGet("/api/Board/delete/" + data);
}
