<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()"
							:disabled="isSaving"
							type="button"
							class="btn btn-info mt-3">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right"
								to="/admin/Board/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="panel_id" class="col-sm-2 col-form-label">板子id</label>
							<div class="col-sm-10">
								<input v-model="model.panel_id"
									type="text"
									class="form-control"
									id="panel_id"
									name="panel_id"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="id" class="col-sm-2 col-form-label">面板組編號</label>
							<div class="col-sm-10">
								<input v-model="model.id"
									type="text"
									class="form-control"
									id="id"
									name="id"
								readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="housecode" class="col-sm-2 col-form-label">案場</label>
							<div class="col-sm-10">
								<input v-model="model.housecode"
									type="text"
									class="form-control"
									id="housecode"
									name="housecode"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="panelname" class="col-sm-2 col-form-label">板子型號</label>
							<div class="col-sm-10">
								<input v-model="model.panelname"
									type="text"
									class="form-control"
									id="panelname"
									name="panelname"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="amount" class="col-sm-2 col-form-label">本組片數</label>
							<div class="col-sm-10">
								<input v-model="model.amount"
									type="text"
									class="form-control"
									id="amount"
									name="amount"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="sumpower" class="col-sm-2 col-form-label">面板組總瓦數(KW)</label>
							<div class="col-sm-10">
								<input v-model="model.sumpower"
									type="text"
									class="form-control"
									id="sumpower"
									name="sumpower"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="buydt" class="col-sm-2 col-form-label">購買日期</label>
							<div class="col-sm-10">
								<input v-model="model.buydt"
									type="date"
									class="form-control"
									id="buydt"
									name="buydt"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="note" class="col-sm-2 col-form-label">備註</label>
							<div class="col-sm-10">
								<input v-model="model.note"
									type="text"
									class="form-control"
									id="note"
									name="note"
								/>
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
	import LayoutDiv from '@/components/LayoutDiv.vue'
	import { addBoard } from '@/api/Board.js'

	export default {
		name: 'ProjectCreate',
		components: {
			LayoutDiv
		},
		data() {
			return {
				model: {
					panel_id: '',
					id: '',
					housecode: '',
					panelname: '',
					amount: '',
					sumpower: '',
					buydt: '',
					note: '',

				},
				isSaving: false,
			};
		},
		methods: {
			handleSave() {
				this.isSaving = true

				addBoard(this.model).then(rep => {

					this.isSaving = false;
					this.reset();
					return rep;
				})
				.catch(error => {
					this.isSaving = false
					return error
				});
			},
			reset() {
					this.model.panel_id = "";
					this.model.id = "";
					this.model.housecode = "";
					this.model.panelname = "";
					this.model.amount = "";
					this.model.sumpower = "";
					this.model.buydt = "";
					this.model.note = "";

			}
		}
	};
</script>
